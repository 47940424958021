<template>
    <div id="experience-form" v-bind:class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white'}">
        <div v-for="experience in experiencesWithItems" class="form-group-experience" :key="experience.id">
            <div v-bind:style="{ background: 'url(' + experience.image + ') no-repeat center center' }" class="form-group-header">
                <p class="experience-name">{{ experience.name }}</p>
                <div class="background-box-date">
                    <p>
                        {{ getDateSelected() }} <br /> {{ $t('hours') }}
                        <span v-for="(room,index) in getItemsExperience(experience.id)" v-bind:key="index">{{ roomHour(room) }}</span>
                    </p>
                    <span @click="$emit('refresh-purchase')" class="modify">{{ $t('modify') }}</span>
                </div>
            </div>
            <div class="more-info-button">
                <more-info v-if="experience.description.length > 0"
                           :title="$t('informationButton')"
                           :description="'<p>' + experience.description + '</p>'"></more-info>
            </div>
            <div class="form-group-content" v-for="(room,index) in getItemsExperience(experience.id)" v-bind:key="index">
                <p v-if="getExperiencesCheckout().length > 1 && !isMultiPrice()" class="room-name">{{ getRoomName(room.id_calendario, experience.id) }} - {{ roomHour(room) }}</p>
                <div class="form-group" v-if="!isMultiPrice()">
                    <player-selector
                            :room="room.id_calendario"
                            :start="room.start"
                            :experience="experience"
                            :id_field="room.id"
                            @change-field="changeField"
                    />
                </div>
                <div class="form-group" v-for="(field,index) in experience.fields" v-bind:key="index">
                    <select-language v-if="field.isActive && field.isLanguage" :field="field" :item="room" :experience="experience" @change-field="changeField"> </select-language>
                </div>
                <form-general  v-if="getItemsExperience(experience.id).length === 1 && getCheckout().items.length === 1" />
                <div class="form-group-order">
                    <div v-for="(field,index) in getFields(experience)" v-bind:class="`${field.type}-form-group`" v-bind:key="index">
                        <input-normal v-if="field.type === 'input'" :experience="experience.id" :start="room.start" :room="room.id_calendario"  :field="field"  @change-field="changeField"/>
                        <select-normal v-if="field.type === 'select' && !field.isLanguage" :experience="experience.id" :start="room.start" :room="room" :field="field" @change-field="changeField"/>
                        <checkbox-normal v-if="field.type === 'checkbox'" :experience="experience.id" :start="room.start" :room="room.id_calendario"  :field="field"  @change-field="changeField"/>
                        <text-area-normal v-if="field.type === 'textarea'" :experience="experience.id" :start="room.start" :room="room.id_calendario"  :field="field" @change-field="changeField"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import MoreInfo from './../../mini-components/more-info.vue';
    import PlayerSelector from './input-components/player-selector.vue'
    import SelectLanguage from './input-components/select-language'
    import SelectNormal from './input-components/select-normal'
    import InputNormal from './input-components/input-normal'
    import TextAreaNormal from './input-components/textarea-normal'
    import CheckboxNormal from './input-components/checkbox-normal'
    import FormGeneral from './../form-general/form-general.vue';
    import formExperience from './form-experience';

    export default {
        extends: formExperience,
        name: "form-experience",
        components: {
            PlayerSelector,
            SelectLanguage,
            SelectNormal,
            InputNormal,
            TextAreaNormal,
            MoreInfo,
            CheckboxNormal,
            FormGeneral
        },
        methods: {
            getFields(experience){
                return experience.fields.filter(f => f.isActive && !f.isBasic);
            }
        },
        computed: {
            experiencesWithItems() {
                return this.getExperiences().filter(experience => this.getItemsExperience(experience.id).length > 0);
            },
        },
    }
</script>

<style lang="scss">
    @import "@/scss/_variables.scss";
    @import "@/scss/_variables_white.scss";
    @import "@/scss/_variables_dark.scss";

   @import "form-experience";
</style>