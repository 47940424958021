import { mapGetters } from 'vuex';

import { changeDay } from '@/main';
import api from '@/api';

export default {
    extends: api,
    props: ['numberPlayers','experience'],
    data() {
        return {
            gaps: 0,
            notFound: false,
            currentMonth: 0,
            currentDay : 0,
            lastDay: 31,
            currentYear: 0,
            days: []
        }
    },
    computed: {
        ...mapGetters(['getDisplayMode','canShowStepTwo','getBlockView']),
        showArrowLeft : function () {
            let date = new Date();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();

            return !(this.currentMonth === month && this.currentYear === year);
        },
        monthName: function () {
            // Obtener el idioma de la URL
            const urlLocale = window.location.pathname.split('/').pop();

            // Función para determinar el locale basado en urlLocale
            function getLocale() {
                switch (true) {
                    case urlLocale.startsWith('ca'):
                        return 'ca-ES';
                    case urlLocale.startsWith('en'):
                        return 'en-EN';
                    case urlLocale.startsWith('gl'):
                        return 'gl';
                    case urlLocale.startsWith('fr'):
                        return 'fr';
                    default:
                        return 'es-ES';
                }
            }

            // Mapeo manual de los nombres de los meses en gallego
            const monthNamesGalician = ['xan', 'feb', 'mar', 'abr', 'mai', 'xuñ', 'xul', 'ago', 'set', 'out', 'nov', 'dec'];

            const date = new Date(this.currentYear, this.currentMonth - 1, 1);
            const locale = getLocale();

            if (locale === 'gl') {
                return monthNamesGalician[this.currentMonth - 1];
            } else {
                return date.toLocaleString(locale, { month: 'short' });
            }
        },
        dayObject: function () {
            return (dayNumber, enabled) => {
                const day = this.days.find(d => d.number === dayNumber);
                const isBlockedView = this.getBlockView() === 'block';

                if(day && enabled) {
                    const checkLow = (day.occupied / day.total) * 100 > 20;
                    const block = day.occupied >= day.total;
                    const isEnabled = day.occupied < day.total && day.total > 0;
                    const isCurrentDay = this.currentDay === dayNumber;
                    return {
                        'current' : isCurrentDay,
                        'current-enabled': isCurrentDay && isEnabled && !checkLow,
                        'current-low': isCurrentDay && isEnabled && checkLow,
                        'enabled': isEnabled,
                        'low': checkLow,
                        'block' : block
                    }
                }else{
                    return {
                        'block': this.checkDateBefore(dayNumber) && day.blocked >= day.total && isBlockedView
                    }
                }
            };
        },
        checkDateBefore : function () {
            return(dayInCalendar) => {
                const day = this.days.find(d => d.date.getDate() === dayInCalendar);
                const date = new Date();
                date.setHours(0, 0, 0, 0);
                return !!day && day.date.getTime() >= date.getTime() && day.total > 0;
            }
        },
        checkDayBlockedView: function () {
            return(dayNumber) => {
                const day = this.days.find(d => d.number === dayNumber);
                return !!day && !(day.blocked >= day.total || day.occupied >= day.total);
            }
        },
        isEnabled: function() {
            return(day) => this.checkDateBefore(day) && this.checkDayBlockedView(day);
        }
    },
    created() {
        this.getCalendar();
        changeDay.$on('day-before', () => {
            this.dayBefore();
        });
        changeDay.$on('day-after', () => {
            this.dayAfter();
        })
    },
    methods: {
        dayBefore: function () {
            if(this.currentDay === 1) {
                const lastDay = new Date(this.currentYear, this.currentMonth - 1, 0);
                this.currentDay = lastDay.getDate();
                this.currentMonth--;
                this.getCalendar();
            }
            else {
                this.currentDay--;
                this.getCalendar(false, true);
            }
        },
        dayAfter: function () {
            const lastDay = new Date(this.currentYear, this.currentMonth - 1, 0);
            if(this.currentDay === lastDay.getDate()) {
                this.nextMonth()
            }
            else {
                this.currentDay++;
                this.getCalendar(false, true);
            }
        },
        nextMonth: function () {
            if(this.currentMonth === 12) {
                this.currentMonth = 1;
                this.currentYear++;
            }
            else this.currentMonth++;

            this.currentDay = 1;
            this.getCalendar(true);
        },
        previusMonth: function () {
            if(this.currentMonth === 1){
                this.currentMonth = 12;
                this.currentYear--;
            }
            else this.currentMonth--;

            this.currentDay = 1;
            this.getCalendar(true);
        },
        getCalendar: function (month, isNextDay) {
            const date = new Date();
            const currentDate = new URLSearchParams(window.location.search).get('dateSelected');
            if(currentDate) {
                const currentDateArray = currentDate.split('/');
                this.currentMonth = currentDateArray[1];
                this.currentDay = currentDateArray[0];
                this.currentYear = currentDateArray[2];
            }else if(this.currentMonth === 0){
                this.currentMonth = date.getMonth() + 1;
                this.currentDay = date.getDate();
                this.currentYear = date.getFullYear();
            }

            let firstDay = new Date(this.currentYear, this.currentMonth - 1, 1);
            let lastDay = new Date(this.currentYear, this.currentMonth, 0);

            this.gaps = firstDay.getDay() - 1;

            if(this.gaps === -1) this.gaps = 6;

            this.lastDay =  lastDay.getDate();

            const firstDayFormated = firstDay.getDate() + '/' + this.currentMonth + '/' +  this.currentYear;
            const lastDayFormated = lastDay.getDate() + '/' + this.currentMonth + '/' +  this.currentYear;

            this.callGetCalendarIds(firstDayFormated, lastDayFormated, isNextDay);
        },
        callGetCalendarIds : function (fromDate, toDate, isNextDay) {
            const datePrommise = new Promise((resolve) => {
                this.getDatesApi(fromDate,toDate, resolve);
            });
            datePrommise.then((dates) => {
                this.days = Object.keys(dates).map(key => {
                    const splittedDate = key.split('/');
                    return {
                        ...dates[key],
                        date: new Date(splittedDate[2], splittedDate[1] - 1, splittedDate[0]),
                        formattedDate: key,
                        number: Number(splittedDate[0])
                    }
                });

                let currentDate = this.days.find(day => this.isEnabled(day.number));
                if(isNextDay){
                    const nextDay = this.days.find(d => d.number === this.currentDay);
                    if(currentDate && nextDay.number >= currentDate.number){
                        currentDate = nextDay
                    }
                }

                if(currentDate){
                    this.currentDay = currentDate.number;
                    this.$emit('date-selected', currentDate.formattedDate);
                }
            });
        },
        selectDay: function (day) {
            const obj = this.dayObject(day, this.checkDateBefore(day) && this.checkDayBlockedView(day));
            if(obj.block || !obj.enabled) return;
            
            if(this.canShowStepTwo()){
                if(confirm(this.$t("cancelModal"))){
                    this.$emit('cancel-purchase');
                }else{
                    return;
                }
            }

            this.currentDay = day;
            let dateSelected = this.currentDay + '/' + this.currentMonth + '/' +  this.currentYear;

            if(document.getElementById("step-one")) document.getElementById("step-one").scrollIntoView();

            this.$emit('date-selected-click', dateSelected)
        },
    }
}
