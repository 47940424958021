<template>
    <div class="form-group" v-bind:class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white'}">
        <input type="email" v-bind:class="{'required-field': field.isRequired}" :placeholder="$t(this.field.name)" v-model="value" :required="field.isRequired" @change="changeInput">
        <span class="email-error">Invalid mail format</span>
    </div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import { mapMutations } from 'vuex';

    export default {
        name: "input-normal-name",
        props: ["field"],
        data() {
            return {
                value: '',
            }
        },
        computed: {
            ...mapGetters(['getDesign','getCheckout'])
        },
        created() {
            this.value = this.getCheckout().mail;
            this.changeInput();
        },
        methods: {
            ...mapMutations(['updateCheckoutField']),
            changeInput: function () {
                this.updateCheckoutField({name: 'mail', value: this.value});
                if(this.getCheckout().id) {
                    this.$emit('change-field');
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .email-error {
        color: #c74646;
        font-size: 0.94rem;
        display: none;

        &.show{
            display: flex;
        }
    }
</style>