<template>
    <div class="more-information-collapse" v-bind:class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white'}">
        <div class="button-more-info" @click="collapse = !collapse">
            {{ this.title }}
            <div class="button-more-info-round">
                <span class="more" v-if="!collapse">+</span>
                <span class="less" v-else>-</span>
            </div>
        </div>
        <div v-show="collapse" class="content-more-info" v-html="this.description"></div>
    </div>
</template>

<script>

    import { mapGetters } from 'vuex';

    export default {
        name: "more-info",
        props: ["title","description"],
        data() {
            return {
                collapse: false
            }
        },
        computed: {
            ...mapGetters(['getDesign']),
        }
    }
</script>

<style lang="scss">

    @import "@/scss/_variables.scss";
    @import "@/scss/_variables_white.scss";
    @import "@/scss/_variables_dark.scss";

    #aion-checkout {
        .more-information-collapse {
            margin-top: 20px;
            width: 100%;
            padding: 10px 25px;
            border-radius: 15px;
            .button-more-info {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #fff;
                text-transform: uppercase;
                font-size: 21px;
                line-height: 20px;
                .button-more-info-round {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 30px;
                    height: 30px;
                    margin-right: -5px;
                    border-radius: 40px;
                    -webkit-transition: 0.3s;
                    transition: 0.3s;
                    span {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        width: 30px;
                        height: 30px;
                        &.more {
                            font-size: 30px;
                            line-height: 30px;
                        }
                        &.less {
                            font-size: 40px;
                            line-height: 35px;
                        }
                    }
                }
                &:hover {
                    cursor: pointer;
                    color: #fec02f;
                    span {
                        color: #fec02f;
                    }
                }
            }
            .content-more-info {
                color: #fff;
                p, * {
                    font-family: 'DIN NextLTPro Condensed', SansSerif;
                    margin-top: 30px;
                    color: #fff;
                }
            }
            &.dark {
                background-color: $background-general-more-info-button-dark;
            }
            &.white {
                background-color: $background-general-more-info-button-white;
            }
        }
    }
</style>