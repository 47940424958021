<template>
    <div id="form-group-phone" class="form-group">
        <vue-tel-input @input="changeInput"
            v-model="value"
            :defaultCountry="'es'"
            :dropdownOptions="{showDialCodeInList: true, showDialCodeInSelection: true, showFlags: true}"
            :inputOptions="{showDialCode: false, placeholder: $t('phone'), required: field.isRequired, styleClasses: field.isRequired ? 'required-field': ''}"
            autoFormat/>
    </div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import { mapMutations } from 'vuex';

    export default {
        name: "input-normal-phone",
        props: ["field"],
        data() {
            return {
                value: '',
                valuePre: ''
            }
        },
        computed: {
            ...mapGetters(['getDesign', 'getCheckout']),
        },
        created() {
            this.value = this.getCheckout().phone;
            this.changeInput();
        },
        methods: {
            ...mapMutations(['updateCheckoutField']),
            changeInput: function (number , phoneObject) {
                if(phoneObject) {
                    this.updateCheckoutField({name: 'phone', value: phoneObject.number ? phoneObject.number.toString().replace('+', '') : ''});
                    if(this.getCheckout().id) {
                        this.$emit('change-field');
                    }
                }
            }
        }
    }
</script>

<style lang="scss">

    @import "@/scss/_variables.scss";
    @import "@/scss/_variables_white.scss";
    @import "@/scss/_variables_dark.scss";

    #form-group-phone {
        .vue-tel-input {
            border-radius: 10px;
            border: none;
            align-items: center;
            .vti__dropdown {
                background-color: #ced8dc;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                max-height: 50px;
                min-height: 50px;
                width: 90px;
                min-width: 90px;
                height: 50px;
                padding: 0 7px;
                border: 1px solid #bbb;
            }
            .vti__input {
                border: none;
                outline: none;
                font-family: 'Alternate Gothic NO2 BT', SansSerif;
                background-color: #fff;
                padding: 15px;
                border-radius: 0;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                color: $primary-color-text-white;
                font-size: 18px;
                line-height: 20px;
                letter-spacing: 2px;
                &::placeholder {
                    color: $second-color-text-white;
                }
            }
        }
    }
</style>