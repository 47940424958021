import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';

export default {
    props: ["experienceContent","moreInfo","multiBooking","urlExperience","showExperienceName","showRoomName","callSunday","call"],
    data() {
        return {
            experienceUrl: '',
            currentDay: null,
            keyList: 0,
            multiCalendarCheck: false,
            currentDayPlus: null
        }
    },
    computed: {
        ...mapGetters(['getDateSelected','getDesign','getMultiCalendar','getIdsLink','getCheckout','isMultiPrice','getDisplayMode']),
        multiCalendar: function () {
            this.multiCalendarCheck = this.getMultiCalendar();
            return this.multiCalendarCheck;
        },
        buttonEnabled : function () {
            return this.getCheckout().items.length > 1;
        },
        getCalendars(){
            return this.isMultiPrice() ? this.experienceContent.calendars.filter((obj, index) => index === 0) : this.experienceContent.calendars;
        },
        getMaxPlayers(){
            return this.isMultiPrice() ? this.experienceContent.maxPlayers * this.experienceContent.calendars.length : this.experienceContent.maxPlayers;
        }
    },
    created() {
        this.currentDay = new Date();
        this.experienceUrl = this.getIdsLink()[ this.experienceContent.id];
        this.currentDayPlus = new Date(this.currentDay.getTime() + this.experienceContent.TTC*60000);
    },
    methods: {
        ...mapMutations(['udpateMultiCalendar']),
        selectSchedule: function () {
            if(!this.multiCalendar) {
                this.$emit('experience-selected');
            }
        },
        setMultiCalendar: function () {
            this.keyList++;
            this.udpateMultiCalendar()
        },
        experienceSelectedButton: function () {
            if(this.buttonEnabled) this.$emit('experience-selected');
        }
    }
}