<template>
    <div v-if="showStripe" id="stripe-component">
        <form method="post" id="payment-form-stripe">
            <div id="card-element">
                <!-- A Stripe Element will be inserted here. -->
            </div>
            <div class="buttons-content">
                <div @click="showStripe = false" class="button button-flat-grey">{{ $t('cancel') }}</div>
                <button id="pagoStripe" class="button button-flat">{{ $t('payWithCard') }}</button>
            </div>
        </form>
    </div>
</template>

<script>
    import stripe from './stripe-component';
    export default {
        extends: stripe,
        name: 'stripe'
    };
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";
    @import "@/scss/_variables_white.scss";
    @import "@/scss/_variables_dark.scss";

    @import"stripe-component";

</style>