import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';

import api from '@/api';

export default {
    extends: api,
    computed: {
        ...mapGetters(['getToken','getExperiences','getCheckout']),
    },
    created() {
        this.item = this.getExperiences()[0]
    },
    methods: {
        ...mapMutations(['updateCheckoutField','updateCheckout']),
        changeField: function () {
            if(this.getCheckout().id) {
                let upatePurchasePrommise = new Promise((resolve) => {
                    this.updatePurchaseApi(resolve);
                });
                upatePurchasePrommise.then((purchase) => {
                    this.updateCheckout({checkout: purchase});
                });
            }
        }
    }
}