<template>
    <div class="calendar-selector"  v-bind:class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white'}">
        <div class="back-button" @click="$emit('back')">{{ $t('Volver') }}</div>
        <div class="experience-form" v-for="experience in filteredExperiences" :key="experience.id">
            <div v-bind:style="{ background: 'url(' + experience.image + ') no-repeat center center' }" class="form-group-header">
                <p class="experience-name">{{ experience.name }}</p>
            </div>
            <div class="form-group-more-info-button">
                <more-info v-if="experience.description.length > 0" :title="$t('informationButton')" :description="'<p>' + experience.description + '</p>'" />
            </div>
            <div class="form-group-content" v-bind:class="{'not-selected' : getTotalPriceCalcutate <= 0}">
                <div class="form-group">
                    <minimal-player-selector :experience="experience" @change-field="changeField" />
                </div>
                <div class="form-group">
                    <date-selected :key="keyDateSelected"
                        :keySchedule="keySchedule"
                        :number-players="numberPlayers"
                        :experience="experience"
                        :call="call" 
                        @next-step="nextStep"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { mapMutations } from 'vuex';
    import MoreInfo from '../mini-components/more-info.vue';
    import MinimalPlayerSelector from '../checkout/form-experience/input-components/minimal-player-selector.vue';
    import DateSelected from '../date-selected/date-selected.vue';

    export default {
        name: "calendar-selector",
        props: ['call'],
        components: {
            MoreInfo,
            MinimalPlayerSelector,
            DateSelected
        },
        data() {
            return {
                keyDateSelected: 1,
                keySchedule: 1,
                numberPlayers: 0,
            }
        },
        computed: {
            ...mapGetters(['getCheckout','getExperiences','getDesign']),
            getItemsExperience: function () {
                return (id_experience) => this.getCheckout().items.filter((item) => item.id_experience === id_experience);
            },
            getTotalPriceCalcutate: function () {
                let total = 0;
                let totalPlayers = 0;
                this.getCheckout().items.map((item) => {
                    if(item.start) {
                        totalPlayers += item.people_number;
                        let experienceSelected = this.getExperiences().find(experience => experience.id === item.id_experience);
                        total = (experienceSelected.prices.prices.find(price => price.people === totalPlayers)) 
                        ? (experienceSelected.prices.prices.find(price => price.people === totalPlayers).full_price * totalPlayers) 
                        : (experienceSelected.prices.prices.find(price => price.people === 999).full_price * totalPlayers);
                    }
                });
                return parseFloat(total).toFixed(2);
            },
            filteredExperiences() {
                const allExperiences = this.getExperiences();
                return allExperiences.filter(experience => this.getItemsExperience(experience.id).length > 0);
            }
        },
        methods: {
            ...mapMutations(['removeMinimalItem']),
            nextStep: function () {
                this.$emit('next-step')
            },
            changeField: function (numberPlayers) {
                this.removeMinimalItem();

                this.numberPlayers = numberPlayers;
                this.keySchedule++;
                this.keyDateSelected++;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "calendar-selector";
</style>
