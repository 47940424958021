class Checkout{
    constructor() {
        this.id = null,
        this.status = '',
        this.mail_status = '',
        this.interna_permanent = false,
        this.id_discount = null,
        this.id_bono = null,
        this.price_final = 0,
        this.price_after_discount = 0,
        this.price_fractioned = 0,
        this.is_fractioned = false,
        this.name = '',
        this.mail = '',
        this.dni = '',
        this.phone = '',
        this.direction = '',
        this.language = '',
        this.code = '',
        this.ip = localStorage.getItem('localIp'),
        this.is_fractioned = '',
        this.items = []
    }
}

export default Checkout;