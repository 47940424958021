<template>
    <div id="aion-checkout" v-bind:class="{ active: enableBackground, 'minimal': displayMode === 'minimal', 'dark': this.getDesign() == 'black' && displayMode === 'minimal', 'white' : this.getDesign() == 'white' && displayMode === 'minimal' }">
        <external-calendar v-if="Object.keys(integration).length > 0 && displayMode !== 'minimal'" 
            :showCalendar="showCalendar" 
            :integration="integration"
            @load-multi-price="loadMultiPrice"/>
        <minimal-calendar v-if="Object.keys(integration).length > 0 && displayMode === 'minimal'" :integration="integration"/>
    </div>
</template>

<script>
    import ExternalCalendar from './components/External.vue';
    import MinimalCalendar from './components/Minimal.vue';
    import { mapMutations } from 'vuex';
    import { mapGetters } from 'vuex';

    import api from '@/api';

    export default {
        extends: api,
        name: "checkout",
        components: {
            ExternalCalendar,
            MinimalCalendar
        },
        data() {
            return {
                enableBackground: false,
                showCalendar: false,
                displayMode: '',
                minHeight: 0,
                terms: '',
                integration: {},
                multiPrice: false
            }
        },
        computed: {
            ...mapGetters(['isMultiPrice']),
        },
        created() {
            this.setCurrentDate();
            this.checkLocalStorage();
            this.callGetToken();
            this.updateShowStepOne(true);
            this.updateShowStepTwo(false);

            let hostname = window.location.hostname;
            this.enableBackground = ( hostname == '192.168.0.142' || hostname.includes('gestorempresas'));
        },
        methods: {
            ...mapMutations(['updateToken',
            'updateDesign',
            'updateIds','updateIdsLinks',
            'updateDateSelected',
            'updatePaymentsMethods',
            'updateDisplayMode',
            'updateBlockView',
            'refreshState',
            'updateShowStepOne',
            'updateShowStepTwo',
            'updateCheckout'
            ]),
            checkLocalStorage() {
                setTimeout(() => {
                    let idPurchase = localStorage.getItem('toPayment');
                    if(idPurchase) {
                        this.refreshAndCancelFromPayment(idPurchase, localStorage.getItem('token'));
                        localStorage.removeItem('toPayment');
                        localStorage.removeItem('token');
                    }
                    else {
                        this.checkLocalStorage();
                    }
                }, 1000)
            },
            callGetToken: function () {
                let tokenPromise = new Promise((resolve) => {
                    this.getTokenApi(resolve);
                });
                tokenPromise.then((token) => {
                    this.updateToken({token: token});
                    this.callGetIds();
                });
            },
            callGetIds: function () {
                let idsPromise = new Promise((resolveOther) => {
                    this.getIdsApi(resolveOther);
                });
                idsPromise.then((integration) => {
                    this.displayMode = integration.displayMode;
                    this.integration = integration;
                    this.showCalendar = true;

                    this.updateDisplayMode(integration.displayMode)
                    this.updateIds({ids: integration.ids});
                    this.updateDesign({design: integration.design});
                    this.updateIdsLinks({idsLink: integration.urls});
                    this.updatePaymentsMethods({paymentMethods: integration.payments});
                    this.updateBlockView(integration.blockview);
                });
            },
            refreshAndCancelFromPayment: function (idPurchase, token) {
                let cancelPromise = new Promise((resolve) => {
                    this.cancelPurchaseById(resolve,idPurchase, token);
                });
                cancelPromise.then(() => {
                    this.refreshPurchase();
                    document.getElementById("aion-checkout").scrollIntoView();
                });
            },
            setCurrentDate: function () {
                let date = new Date();
                let dateSelectedInitial =  date.getDate() + '/' +  (date.getMonth() + 1 ) + '/' +  date.getFullYear();
                this.updateDateSelected({dateSelected: dateSelectedInitial, multiCalendar: true});
            },
            refreshPurchase: function () {
                this.cleanData();
                this.refreshState();
                this.updateShowStepOne(true);
                this.updateShowStepTwo(false);
                this.showCalendar = true;
                this.stepOneKey++;
            },
            cleanData: function() {
                const checkout = this.getCheckout();
                checkout.mail = "";
                checkout.name = "";
                checkout.dni = "";
                checkout.phone = "";
                this.updateCheckout({ checkout });
            },
            loadMultiPrice(value){
                this.multiPrice = value;
            }
        }
    }
</script>

<style lang="scss">

    @import "@/scss/_variables.scss";
    @import "@/scss/_variables_white.scss";
    @import "@/scss/_variables_dark.scss";

    @import "@/scss/_general.scss";

    #aion-checkout {
        width: 100%;
        border-radius: 25px;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        font-family: 'Alternate Gothic NO2 BT', SansSerif;
        box-sizing: border-box;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 2px;
        &.active {
            background-color: #fff;
        }
        * {
            box-sizing: border-box;
        }

        &.minimal{
            width: 414px;
            padding: 15px;
            border-radius: 20px;

            * {
                letter-spacing: 0;
            }

            .powered-by {
                text-align: right;
                font-size: 14px;
                color: #5e686c;
                padding: 0.5rem;
                a {
                    text-decoration: none;
                    color: #fec02f;
                }
            }
        }

        .left-box-zone {
            flex-basis: calc(40% - 10px);
            width: calc(40% - 10px);
            .calendar {
                width: 100%;
                height: 400px;
            }
        }
        .right-box-zone{
            flex-basis: calc(60% - 10px);
            width: calc(60% - 10px);
            .no-available-days {
                p {
                    color: #AAA;
                    mix-blend-mode: difference;
                    opacity: 1;
                }
            }
        }
        &.dark {
            background-color: $background-dark;
        }
        &.white {
            background-color: $background-white;
        }
    }

    @media screen and (max-width: 1024px) {
        #aion-checkout {
            .left-box-zone,
            .right-box-zone{
                flex-basis: 100%;
                width: 100%;
            }
            .general{
                flex-wrap: wrap;
            }
        }
    }

    @media screen and (min-width: 600px){
        #aion-checkout {
            &.minimal{
                width: 414px;
            }
        }
    }
</style>
