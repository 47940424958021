<template>
    <div class="small-experience" v-bind:class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white'}">
        <div class="background-box" @click="selectSchedule" v-bind:style="{ background: 'url(' + this.experienceContent.image + ') no-repeat top center' }">
            <div class="header-box" style="margin-bottom: 10px;">
                <p v-if="showExperienceName" class="experience-name">{{ this.experienceContent.name }}</p>
                <div  class="button-flat button-flat-green" style="letter-spacing: 2px;margin-top: 10px;">{{ $t('RESERVAR') }}</div>
            </div>
            <div class="background-box-icons">
                <div class="icon">
                    <img src="./assets/people.png" alt="">
                    {{ this.experienceContent.minPlayers }} - {{ this.experienceContent.maxPlayers * this.getNumberCalendars() }} {{ $t('people') }}
                </div>
                <div class="icon">
                    <img src="./assets/time_info.png" alt="">
                    {{ this.experienceContent.duration * this.experienceContent.sessionMultiplier }} {{ $t('minutes') }}
                </div>
                <div class="icon" v-bind:class="{'no-visible': !experienceContent.ageinfo}">
                    <img :src="require('./assets/older.png')" alt="">
                    {{ this.experienceContent.ageinfo }}
                </div>
                <div class="icon" v-bind:class="{'no-visible': !experienceContent.location}">
                    <img :src="require('./assets/location.png')" alt="">
                    {{ this.experienceContent.location }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import experience from './experience';
    import ItemCheckout from '../../classes/ItemCheckOut';
    import { mapMutations } from 'vuex';

    export default {
        extends: experience,
        name: 'small-experience',
        methods: {
            ...mapMutations(['addItem']),
            getNumberCalendars: function () {
                return this.experienceContent.calendars.length;
            },
            selectSchedule: function () {
                let itemCheckout = new ItemCheckout(
                    this.experienceContent.id,
                    this.getCalendar,
                    null,
                    null,
                    null,
                    null,
                    this.experienceContent.prices.prices[0].people,
                    this.experienceContent.prices.prices[0].full_price,
                    this.experienceContent.prices.partial_price
                );

                this.addItem({item: itemCheckout});

                this.$emit('experience-selected');
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "minimal-experience";
</style>
