<template>
    <div class="form-group" v-bind:class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white'}">
        <label>{{ $t(this.field.name) }}</label>
        <select v-model="idSelectedField" :required="field.isRequired" @change="changeField">
            <option :value="index" v-for="(option, index) in this.field.options" v-bind:key="index">{{ changeNameLanguage(option.name) }}</option>
        </select>
    </div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import { mapMutations } from 'vuex';

    export default {
        name: "select",
        props: ["field","experience","item"],
        data() {
            return {
                idSelectedField: 0,
            }
        },
        created() {
            this.changeField();
        },
        computed: {
            ...mapGetters(['getDesign','getCheckout']),
          changeNameLanguage: function () {
            return (name) => {
              if (name === 'Spanish') return this.$t('Español');
              else if (name === 'English (United States)') return this.$t('English');
              else if (name === 'Catalan') return this.$t('Català');
              else if (name === 'Galician') return this.$t('Galego');
              else return '';
            }
          }
        },
        methods: {
            ...mapMutations(['updateCheckoutField','updateItemField', 'changeItem']),
            changeField() {
                const option = this.field.options[this.idSelectedField];
                this.updateCheckoutField({name: 'language', value: option.value});

                const item = this.getCheckout().items.find(item => {
                    return item.id_calendario === this.item.id_calendario
                    && item.id_experience === this.experience.id
                    && item.start === this.item.start;
                });

                if(item){
                    item.language = option.value;
                }

                if(this.getCheckout().id) {
                    this.$emit('change-field');
                }
            }
        }
    }
</script>
