<template>
    <div class="date-selected-container">
        <div id="calendar-box" class="form-group date-selected" v-bind:class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white'}">
            <label for="">{{ $t('Fechas y horas disponibles') }}</label>
            <div class="input-select" @click="openCalendar">
                {{ getDateSelected() }}
            </div>
            <div v-show="showCalendarBox" class="calendar-box">
                <calendar @date-selected="dateSelected"
                          @date-selected-click="dateSelectedClick"
                          :experience="experience"/>
            </div>
        </div>

        <div id="schedules-box" class="form-group schedule-selected" v-bind:class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white'}">
            <div class="input-select" @click="showScheduleBox = (searchSchedule) ? false : !showScheduleBox">
                {{ getItemsExperience }}
            </div>
            <div v-show="showScheduleBox" class="schedule-box">
                <minimal-schedules 
                    :key="keySchedules" 
                    :number-players="numberPlayers" 
                    :experience="experience" 
                    :call="call"
                    @schedule-selected="scheduleSelected" 
                    @schedule-loaded="onScheduleLoaded"
                />
            </div>
        </div>
    </div>

</template>

<script>
    import { mapMutations } from 'vuex';
    import { mapGetters } from 'vuex';

    import Calendar from '../calendar/calendar.vue';
    import MinimalSchedules from '../minimal-schedules/minimal-schedules.vue'

    export default {
        name: 'date-selected',
        props: ['numberPlayers','experience','call'],
        components: { Calendar, MinimalSchedules },
        data() {
            return {
                showCalendarBox: false,
                showScheduleBox: false,
                searchSchedule: false,
                keySchedules: 0
            }
        },
        computed: {
            ...mapGetters(['getDateSelected','getDesign','getCheckout']),
            getItemsExperience: function () {
                if (this.searchSchedule){
                  this.getCheckout().items[0].start = null;
                  return this.$t('Buscando disponibilidad...');
                }
                else if(this.getCheckout().items[0].start) {
                    return this.getCheckout().items[0].startHour;
                }
                else{
                    return this.$t('Seleccione una hora');
                }
            }
        },
        methods: {
            ...mapMutations(['updateDateSelected']),
            dateSelected: function (dateSelected) {
                this.updateDateSelected({dateSelected: dateSelected, notUpdateCheckout: true});
            },
            dateSelectedClick: function (dateSelected) {
                this.showCalendarBox = false;
                this.showScheduleBox = false;
                this.searchSchedule = true;
                this.updateDateSelected({dateSelected: dateSelected, notUpdateCheckout: true});
                this.keySchedules++;
            },
            openCalendar: function() {
                this.showCalendarBox = !this.showCalendarBox;
                document.getElementById("calendar-box").scrollIntoView(true);
            },
            notAvailableDay: function (notAvailableDay) {
                this.notAvailableDayShow = notAvailableDay;
            },
            scheduleSelected: function () {
                this.showScheduleBox = false;
            },
            onScheduleLoaded: function () {
                this.searchSchedule = false;
                this.showScheduleBox = true;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/scss/_variables.scss";
    @import "@/scss/_variables_white.scss";
    @import "@/scss/_variables_dark.scss";

    @import "date-selected";
</style>
