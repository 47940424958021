<template>
    <div id="form-group-bonus">
        <div class="form-group check-group" v-bind:class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white'}">
            <input @click="createNewPurchase" type="checkbox" v-model="checkBonus">
            <label>{{ $t('Tengo bono/descuento') }}</label>
        </div>

        <div class="form-group form-group-bonus"  v-bind:class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white'}" v-if="checkBonus">
            <input type="text" v-model="bonus" :placeholder="$t('Introduce tu código')" >
            <span @click="applyBonus">{{ $t('Aplicar') }}</span>
        </div>

        <p v-if="noValidBonus && checkBonus" style="color: #d51515">Bono no válido o caducado</p>
    </div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import { mapMutations } from 'vuex';

    import api from '@/api';

    export default {
        extends: api,
        name: "bonus",
        data() {
            return {
                checkBonus: false,
                noValidBonus: false,
                bonus: '',
                bonusText: '',
            }
        },
        computed: {
            ...mapGetters(['getDesign','getCheckout']),
        },
        methods: {
            ...mapMutations(['updateCheckoutField','updateCheckout','updateIdPurchase']),
            createNewPurchase: function () {
                if(this.getCheckout().id == null) {
                    let createPurchasePrommise = new Promise((resolve) => {
                        this.createPurchaseApi(resolve);
                    });
                    createPurchasePrommise.then((purchase) => {
                        this.updateIdPurchase({idPurchase: purchase.id});
                        this.updateCheckout({checkout: purchase});
                        //document.getElementById("step-two").scrollIntoView();
                    });
                }
            },
            applyBonus: function () {

                this.noValidBonus = false;
                this.updateCheckoutField({name: 'code', value: this.bonus});

                let updatePurchasePromise = new Promise((resolve) => {
                    this.updatePurchaseApi(resolve);
                });
                updatePurchasePromise.then((purchase) => {

                    this.updateCheckout({checkout: purchase});
                    this.bonusText = this.bonus;
                    this.bonus = '';
                    this.noValidBonus = (purchase.id_discount === null && purchase.id_bono === null);

                    if(!this.noValidBonus) this.updateCheckoutField({name: 'code', value: this.bonusText});
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
    #aion-checkout {
        #form-group-bonus {
            margin-top: 15px;
            margin-bottom: 30px;
            .check-group {
                margin-left: 10px;
                display: flex;
                align-items: center;
                label {
                    margin: 0 0 0 10px;
                }
            }
            .form-group-bonus {
                display: flex;
                align-items: center;
                height: 50px;
                max-height: 50px;
                min-height: 50px;
                input {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
                span {
                    display: inline-block;
                    text-transform: uppercase;
                    padding: 15px 10px;
                    height: 50px;
                    background-color: #30a64a;
                    color: #fff;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
</style>
