<template>
    <div id="step-two" v-if="loading" v-bind:class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white'}">
        <form @submit="checkForm" >
            <form-experience @refresh-purchase="$emit('cancel-purchase')"/>
            <div v-if="getCheckout().items.length > 1" id="form-general-content">
                <form-general />
            </div>
            <div id="general-conditions-content">
                <div class="form-group-content">
                    <div class="form-group form-group-checkbox"  v-bind:class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white'}">
                        <label for="conditions_check">{{ $t('readTerms') }} <a target="_blank" :href="terms">{{ $t('readTerms2') }}</a></label>
                        <input id="conditions_check" name="conditions_check" type="checkbox" @click="createNewPurchase" required>
                    </div>
                </div>
            </div>
            <resume :key="resumeKey" :call-sunday="callSunday" @change-payment-method="changePaymentMethod"/>
        </form>

        <div v-if="showFormsPayments">
            <stripe-component :key="stripeKey" :paymentMethod="paymentMethod" v-if="paymentMethodId === 3"/>
            <form v-if="paymentMethod" style="display: none;" id="final-form"> </form>
        </div>
    </div>
</template>

<script>

    import FormExperience from './form-experience/form-experience.vue';
    import FormGeneral from './form-general/form-general.vue';
    import Resume from './resume/resume.vue';
    import StripeComponent from './stripe-component/stripe-component.vue'

    import checkout from './checkout';

    export default {
        extends: checkout,
        name: 'checkout',
        components: {
            FormExperience,
            FormGeneral,
            Resume,
            StripeComponent
        }
    };
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";
    @import "@/scss/_variables_white.scss";
    @import "@/scss/_variables_dark.scss";

    @import "checkout";
</style>