import { render, staticRenderFns } from "./schedule-list.vue?vue&type=template&id=65dd0931&scoped=true"
import script from "./schedule-list.vue?vue&type=script&lang=js"
export * from "./schedule-list.vue?vue&type=script&lang=js"
import style0 from "./schedule-list.vue?vue&type=style&index=0&id=65dd0931&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65dd0931",
  null
  
)

export default component.exports